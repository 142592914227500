import React, { MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'

import { MenuPointOptionType } from '@types'

import { useOutsideClickScrollRef } from './hooks/useOutsideClickScrollRef'
import ItemMenu from './ItemMenu'
import { NestedMenuItem } from './NestedMenuItem'

export type CursorPosition = {
  top: number
  left: number
}

export type DataY = Record<string, any>
export type DataX = Record<string, any>
export type HandlerData = {
  menuItem: MenuPointOptionType
  event: MouseEvent<HTMLDivElement | HTMLLIElement>
}
export type MetaData = Record<string, any>
export type Changes = Record<string, { value?: string | null; pathStr?: string }>

export type ActionData = {
  data?: DataY | DataX
  config?: Record<string, any>
  metaData: MetaData & HandlerData
  changes?: Changes
}

export type ContextMenuItem = MenuPointOptionType

export type ContextMenu = ContextMenuItem[]

export type ContextMenuState = {
  options: ContextMenuItem[]
  dataEl: ActionData | null
  position?: CursorPosition
  handleItemClick?: (item: ActionData) => void
}

export type NestedMenuProps = {
  items?: ContextMenu
  dataTransfer: (Omit<ActionData, 'metaData'> & { metaData?: MetaData }) | null
  onItemClick?: (item: ActionData) => void
  onClose: () => void
}

const NestedMenu = ({
  items,
  dataTransfer,
  onItemClick: handleItemClick,
  onClose: handleClose,
}: NestedMenuProps) => {
  const outsideClickScrollRef = useOutsideClickScrollRef(() => handleClose())
  const { t } = useTranslation()

  const handleMenuItemClick = (
    item: ContextMenuItem,
    event: MouseEvent<HTMLLIElement | HTMLDivElement>
  ) => {
    const dataTransferWithMenuItem = {
      data: dataTransfer?.data,
      metaData: {
        ...dataTransfer?.metaData,
        event,
        menuItem: item,
      },
    }

    dataTransfer && handleItemClick?.(dataTransferWithMenuItem)

    handleClose()
  }

  return (
    <div ref={outsideClickScrollRef}>
      {items?.map(menuitem => {
        const hasOptions = Boolean(menuitem?.childrenMenu?.length)

        return hasOptions ? (
          <NestedMenuItem
            key={menuitem.id}
            dataTransfer={dataTransfer}
            menuitem={menuitem}
            onClick={event => handleMenuItemClick(menuitem, event)}
          >
            <NestedMenu
              dataTransfer={dataTransfer}
              items={menuitem?.childrenMenu}
              onClose={handleClose}
              onItemClick={handleItemClick}
            />
          </NestedMenuItem>
        ) : (
          <ItemMenu
            {...menuitem}
            key={menuitem.id}
            dataTransfer={dataTransfer}
            onClick={event => handleMenuItemClick(menuitem, event)}
          />
        )
      })}
      {!items?.length && (
        <Typography color='gray' m={1}>
          {t('contextMenu.noOptions')}
        </Typography>
      )}
    </div>
  )
}

export default NestedMenu
