import { FC, useContext } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FormGenerator, ModalWrapper } from '@microservices/wiskey-react-components'
import { Box, CircularProgress, Grid } from '@mui/material'

import { PageContext } from '@pages/FormCreateOrEdit'
import { useAddFieldDialog } from '@pages/FormCreateOrEdit/components/AddFieldDialog/hooks'

import { DropDownInputWithFitlers } from '@components/DropDownInputWithFitlers'
import { FilterDialog } from '@components/FilterDialog'
import { FieldPickerController } from '@components/hookFormControllers/FieldPickerController'
import { ScriptValueDialog } from '@components/ScriptValueDialog'

import { isObjectValueType } from '@helpers'
import { BIND_TYPE, DROPDOWN_FILTER_TYPES, MODAL_TYPE } from '@constants'

export const AddFieldDialog: FC = () => {
  const { t } = useTranslation()

  const { data, state, handlers, inputs } = useAddFieldDialog()

  const { methods, valueType } = data

  const { fieldInputs, dropwDownWindowFormInput, dropwDownListFormInput } = inputs

  const {
    handleSubmit,
    formState: { isDirty },
    watch,
    setValue,
  } = methods

  const { isShowFilterDialog, currentFilter, isLoading, isFieldsLoading, isEdit } = state

  const {
    handleSave,
    handleCloseFilterDialog,
    handleDeleteFilter,
    handleSubmitFilter,
    handleAddFilter,
    handleDoubleClickChip,
  } = handlers

  const {
    handleCloseModal,
    showDialog,
    modalType,
    isShowScriptValueDialog,
    objectFieldsForFilter,
    toggleOpenScriptValueDialog,
  } = useContext(PageContext)

  const watchBinding = watch('bindType')
  const watchValue = watch('value')
  const watchedDropDownListFilters = watch('dropDownListFilters')
  const watchDropdownList = watch('dropDownListCode')
  const watchedDropDownWindowFilters = watch('dropDownWindowFilters')
  const watchDropdownWindow = watch('dropDownWindowCode')
  const watchPreFillSourceObject = watch('preFillSourceObject')

  return (
    <ModalWrapper
      containerStyle={{ px: 0.5, py: 0.5, borderRadius: 0 }}
      disabledSave={!isDirty}
      isShow={showDialog}
      title={`${t(`addDialog.${modalType}`)} Field`}
      onClose={() => handleCloseModal(isDirty)}
      onSave={handleSubmit(handleSave)}
    >
      {isShowFilterDialog && (
        <FilterDialog
          filter={currentFilter}
          isShow={isShowFilterDialog}
          onClose={handleCloseFilterDialog}
          onSubmit={handleSubmitFilter}
        />
      )}
      {isLoading && modalType === MODAL_TYPE.EDIT ? (
        <Grid container alignItems={'center'} justifyContent={'center'} my={3}>
          <CircularProgress />
        </Grid>
      ) : (
        <Box pb={0.25} pt={1}>
          <FormProvider {...methods}>
            {isShowScriptValueDialog && (
              <ScriptValueDialog
                windowBoundaries
                isJsx={watchBinding === BIND_TYPE.JSX}
                isShow={isShowScriptValueDialog}
                modalType={modalType}
                objectFields={objectFieldsForFilter}
                value={watchValue}
                onClose={() => toggleOpenScriptValueDialog(false)}
                onSave={value => setValue('value', value, { shouldDirty: true })}
              />
            )}
            <form>
              <FormGenerator inputs={fieldInputs} loading={isFieldsLoading} mainGridSpacing={0} />
              <FieldPickerController
                isEdit={true}
                name={'preFillLink'}
                watchedObject={watchPreFillSourceObject}
              />
              {isObjectValueType(valueType) && (
                <>
                  <DropDownInputWithFitlers
                    filters={watchedDropDownListFilters}
                    inputs={[dropwDownListFormInput]}
                    loading={isFieldsLoading}
                    watchInput={watchDropdownList}
                    onAddFilter={() => handleAddFilter(DROPDOWN_FILTER_TYPES.LIST)}
                    onDeleteFilter={filter =>
                      handleDeleteFilter(filter, DROPDOWN_FILTER_TYPES.LIST)
                    }
                    onDoubleClickFilter={filter =>
                      handleDoubleClickChip(filter, DROPDOWN_FILTER_TYPES.LIST)
                    }
                  />
                  <DropDownInputWithFitlers
                    filters={watchedDropDownWindowFilters}
                    inputs={[dropwDownWindowFormInput]}
                    loading={isFieldsLoading}
                    watchInput={watchDropdownWindow}
                    onAddFilter={() => handleAddFilter(DROPDOWN_FILTER_TYPES.WINDOW)}
                    onDeleteFilter={filter =>
                      handleDeleteFilter(filter, DROPDOWN_FILTER_TYPES.WINDOW)
                    }
                    onDoubleClickFilter={filter =>
                      handleDoubleClickChip(filter, DROPDOWN_FILTER_TYPES.WINDOW)
                    }
                  />
                </>
              )}
            </form>
          </FormProvider>
        </Box>
      )}
    </ModalWrapper>
  )
}
