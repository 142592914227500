import { FC, useRef } from 'react'
import { HexColorPicker } from 'react-colorful'
import { Box, SxProps } from '@mui/material'

import { useClickOutside } from '@pages/Parameters/hooks'

type ColorPickerProps = {
  open: boolean
  color?: string
  onChange: (value: string) => void
  onClickOutside: () => void
  sx?: SxProps
}

export const ColorPicker: FC<ColorPickerProps> = ({
  open,
  color,
  sx,
  onChange,
  onClickOutside,
}) => {
  const popover = useRef<HTMLDivElement>(null)

  useClickOutside(popover, onClickOutside)

  if (!open) {
    return null
  }

  return (
    <Box
      ref={popover}
      className='popover'
      position='absolute'
      right='-214px'
      sx={sx}
      top='-202px'
      zIndex={theme => theme.zIndex.tooltip}
    >
      <HexColorPicker color={color} onChange={onChange} />
    </Box>
  )
}
