import { FC, useContext, useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { FormGenerator } from '@microservices/wiskey-react-components'
import { FormInputsType } from '@microservices/wiskey-react-components/dist/types/forms'

import { PageDisplayRulesContext } from '@pages/DisplayRulesCreateOrEdit'
import {
  DisplayRuleValueShotType,
  DisplayRuleValueType,
} from '@pages/DisplayRulesCreateOrEdit/types'

import { ModalWrapper } from '@components/ModalWrapper'

import { useFetchAllStyleSettingsQuery } from '@redux/api/styleSetting.api'

import { GENERATOR_INPUT_TYPE, MODAL_TYPE } from '@constants'

export const AddDisplayRulesValueDialog: FC = () => {
  const { t } = useTranslation()

  const {
    showDialog,
    handleCloseModal,
    modalType,
    displayRuleId,
    handleSetFields,
    editableFieldValue,
    handleSetShowDialog,
    fields,
  } = useContext(PageDisplayRulesContext)

  const displayRuleDefaultValue: DisplayRuleValueShotType = {
    id: undefined,
    fieldValue: '',
    displayRuleId,
    styleSettingId: undefined,
    styleSetting: {
      code: '',
      label: '',
    },
  }

  const methods = useForm<DisplayRuleValueShotType>({
    defaultValues: displayRuleDefaultValue,
  })

  const {
    handleSubmit,
    watch,
    formState: { isDirty },
    reset,
  } = methods

  const watchFieldValue = watch('fieldValue')
  const watchStyleSetting = watch('styleSetting')
  const isEdit = modalType === MODAL_TYPE.EDIT

  useEffect(() => {
    if (!isEdit) {
      reset(displayRuleDefaultValue)
    } else if (isEdit && editableFieldValue) {
      reset({
        id: editableFieldValue.id,
        fieldValue: editableFieldValue.fieldValue,
        displayRuleId,
        styleSettingId: editableFieldValue?.styleSettingId ?? null,
        styleSetting: {
          id: editableFieldValue?.styleSetting?.id ?? editableFieldValue?.styleSettingId,
          label: editableFieldValue?.styleSetting?.title ?? editableFieldValue?.styleSettingsTitle,
        },
      })
    }
  }, [isEdit])

  const { data: styleSettingsData, isLoading: isLoadingStyleSettingsData } =
    useFetchAllStyleSettingsQuery({}, { refetchOnMountOrArgChange: true })

  const autocompleteOptionsStyleSettings = useMemo(() => {
    if (styleSettingsData) {
      return styleSettingsData.map(style => ({ id: style.id, label: style.title }))
    }
  }, [styleSettingsData])

  const handleSave = (data: DisplayRuleValueType) => {
    const { id } = data

    const displayRuleValue: DisplayRuleValueType = {
      id: !isEdit ? uuid() : id,
      fieldValue: watchFieldValue,
      displayRuleId: displayRuleId ? displayRuleId : undefined,
      styleSettingId: watchStyleSetting?.id,
      styleSettingsTitle: watchStyleSetting?.label,
    }

    handleSetFields(displayRuleValue, Boolean(id))
    handleSetShowDialog(false)
  }

  const fieldInputs: FormInputsType[] = [
    {
      name: 'fieldValue',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: t('placeholder.fieldValue'),
      label: t('label.fieldValue'),
      disabled: isEdit,
      rules: {
        required: true,
        validate: value => {
          if (!isEdit && fields.find(field => field.fieldValue === value)) {
            return `${value} ${t('displayRules.errors.alreadyUsed')}`
          }
        },
      },
    },
    {
      name: 'styleSetting',
      inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
      placeholder: t('placeholder.styleSettings'),
      label: t('label.styleSettings'),
      loading: isLoadingStyleSettingsData,
      rules: {
        required: true,
        validate: value => {
          if (!value.id) {
            return ''
          }
        },
      },
      autocompleteOptions: autocompleteOptionsStyleSettings,
    },
  ]

  return (
    <FormProvider {...methods}>
      {showDialog && (
        <ModalWrapper
          disabledSave={!isDirty}
          isShow={showDialog}
          title={t(`displayRules.titleField.${modalType}`)}
          onClose={() => handleCloseModal(isDirty)}
          onSave={handleSubmit(handleSave)}
        >
          <FormGenerator inputs={fieldInputs} />
        </ModalWrapper>
      )}
    </FormProvider>
  )
}
