import { useEffect } from 'react'
import moment from 'moment-timezone'

import { ReturnTransformCommonParametersToValues } from '@helpers'
import { TIMEZONES } from '@constants'

export const useDefaultTimezone = (
  initialCommonParameters: ReturnTransformCommonParametersToValues
) => {
  useEffect(() => {
    if (initialCommonParameters.timezone) {
      moment.tz.setDefault(initialCommonParameters.timezone || TIMEZONES.MOSCOW)
      moment.updateLocale('en', {
        week: {
          dow: 1,
        },
      })
    }
  }, [initialCommonParameters])
}
