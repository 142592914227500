import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import isEqual from 'lodash.isequal'

import { useDisplayRules } from '@pages/DisplayRules/hooks'
import { DisplayRuleValueType, FormDisplayRuleType } from '@pages/DisplayRulesCreateOrEdit/types'

import {
  useCreateOrUpdateDisplayRuleMutation,
  useFetchAllObjectQuery,
  useFetchDisplayRuleByIdQuery,
  useFetchObjectByCodeQuery,
} from '@redux/api'
import { useFetchAllStyleSettingsQuery } from '@redux/api/styleSetting.api'

import { usePrompt } from '@hooks'
import { MODAL_TYPE, ROUTES } from '@constants'
import { ModalType, ObjectFieldDTO, ShortStyleSetting } from '@types'

type ObjectFieldDTOWithStylesSetting = (ObjectFieldDTO[] & ShortStyleSetting[]) | undefined

export const useHandlers = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { id } = useParams()
  const [fieldsSelectedObject, setFieldsSelectedObject] = useState<ObjectFieldDTOWithStylesSetting>(
    []
  )
  const [skipDirty, setSkipDirty] = useState(false)

  const { data: objects } = useFetchAllObjectQuery()

  const { data: displayRuleById, isLoading: isLoadingDisplayRuleById } =
    useFetchDisplayRuleByIdQuery(
      { id },
      {
        skip: !id,
        refetchOnMountOrArgChange: true,
      }
    )

  const { data: styleSettingsData, isLoading: isLoadingStyleSettingsData } =
    useFetchAllStyleSettingsQuery({}, { refetchOnMountOrArgChange: true })

  const styleSettingScriptValue = displayRuleById?.jsSetting?.styleSettingScript
    ? displayRuleById?.jsSetting?.styleSettingScript
    : ''

  const getStyleSettingCodes = () => {
    if (!displayRuleById?.jsSetting?.styleSettings) {
      return []
    }

    return displayRuleById?.jsSetting?.styleSettings?.map(style => {
      return { id: style.id, label: style.code }
    })
  }

  const styleSettingCodes = getStyleSettingCodes()

  const displayRuleId = displayRuleById?.id

  const initFields: DisplayRuleValueType[] = useMemo(
    () =>
      displayRuleById?.displayRuleValues.map(el => ({
        id: el.id,
        fieldValue: el?.objectFieldValue,
        styleSettingsTitle: el?.styleSetting?.title,
        styleSettingId: el?.styleSetting?.id,
        displayRuleId,
      })),
    [displayRuleById]
  )
  const [editableFieldValue, setEditableFieldValue] = useState<DisplayRuleValueType>(
    {} as DisplayRuleValueType
  )
  const [fields, setFields] = useState<DisplayRuleValueType[]>(initFields ?? [])
  // если в таблицу с displayRuleValues внесли изменения (добавили/убрали/отредактирвоали филд)
  const [dirtyOnField, setDirtyOnField] = useState(false)

  useEffect(() => {
    if (initFields) {
      setFields(initFields)
    }
  }, [initFields])

  const methods = useForm<FormDisplayRuleType>({
    defaultValues: {
      displayRuleId: undefined,
      internalId: undefined,
      isJsDisplayRule: false,
      id: undefined,
      object: null,
      objectField: null,
      styleSettingCodes: [],
      styleSettingScript: '',
      title: '',
      titleJsValue: undefined,
    },
  })

  const {
    watch,
    handleSubmit,
    formState: { isDirty },
    reset,
    setValue,
  } = methods

  const watchObject = watch('object')
  const watchIsJsDisplayRule = watch('isJsDisplayRule')
  const watchStylesCodes = watch('styleSettingCodes')
  const watchValueJs = watch('styleSettingScript')

  const isDirtyJsField = styleSettingScriptValue !== watchValueJs

  useEffect(() => {
    if (displayRuleById) {
      reset({
        internalId: displayRuleById.code ? displayRuleById.code : '',
        title: displayRuleById.title ? displayRuleById.title : '',
        object:
          displayRuleById.objectTitle && displayRuleById.objectCode
            ? { id: displayRuleById.objectCode, label: displayRuleById.objectTitle }
            : null,
        objectField: displayRuleById.objectField
          ? { id: displayRuleById.objectField, label: displayRuleById.objectField }
          : null,
        isJsDisplayRule: displayRuleById.isJs ? displayRuleById.isJs : false,
        styleSettingCodes: styleSettingCodes ? styleSettingCodes : [],
        displayRuleId,
        styleSettingScript: displayRuleById?.jsSetting?.styleSettingScript ?? '',
        titleJsValue: watchValueJs,
      })
    }
  }, [displayRuleById])

  const handleSetFields = (data: DisplayRuleValueType, isEdit: boolean) => {
    if (isEdit && editableFieldValue.id === data.id) {
      const filteredFields = fields.filter(field => field.id !== data.id)
      setFields([...filteredFields, data])
      setDirtyOnField(!isEqual(initFields, [...filteredFields, data]))

      return
    }
    setFields([...fields, data])
    setDirtyOnField(!isEqual(initFields, [...fields, data]))
  }

  const { data: selectedObject } = useFetchObjectByCodeQuery(watchObject?.id, {
    skip: !watchObject?.id,
  })

  useEffect(() => {
    if (selectedObject?.fields) {
      setFieldsSelectedObject(selectedObject?.fields)
    }
  }, [selectedObject])

  const [createOrUpdateDisplayRule, { isLoading: isLoadingCreateOrUpdate }] =
    useCreateOrUpdateDisplayRuleMutation()

  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [modalType, setModalType] = useState<ModalType>(MODAL_TYPE.CREATE)
  const [displayRuleValueID, setDisplayRuleValueID] = useState<number | undefined>(undefined)
  const [showScriptValueDialogTitle, setShowScriptValueDialogTitle] = useState<boolean>(false)

  const isCreate = ROUTES.CONFIG_DISPLAY_RULES_CREATE === location.pathname
  const isEdit = !!id

  const isLeaveEdit = !isCreate && !!displayRuleById && !skipDirty && isDirty
  const isLeaveCreate =
    !isEdit && !isLoadingCreateOrUpdate && !displayRuleById && !skipDirty && isDirty

  usePrompt({ when: isLeaveEdit || isLeaveCreate })

  const handleSetShowScriptValueDialogTitle = (value: boolean) =>
    setShowScriptValueDialogTitle(value)

  const handleSave = handleSubmit((displayRuleData: FormDisplayRuleType) => {
    const {
      internalId,
      objectField,
      object,
      isJsDisplayRule,
      title,
      jsSettingId,
      styleSettingCodes,
    } = displayRuleData

    const styleCodesStringArray = styleSettingCodes?.map(style => style?.label)

    const objectCode = watchObject?.id ? watchObject?.id : null

    const fieldsPOST = fields.map(field => {
      // отправляем на бэк без styleSettingsTitle и id => приходят каждый раз новые id у displayRuleValues
      const { styleSettingsTitle, id, ...rest } = field
      setDirtyOnField(false)

      return { ...rest }
    })

    const displayRuleValues = fields ? [...fieldsPOST] : []

    const jsSettingMap = {
      ...(jsSettingId ? { id: jsSettingId } : {}),
      styleSettingScript: watchValueJs ?? '',
      displayRuleId,
      styleSettingCodes: styleCodesStringArray,
    }
    setSkipDirty(true)
    createOrUpdateDisplayRule({
      ...(id ? { id } : {}),
      code: internalId,
      objectCode,
      title,
      objectTitle: object?.label,
      objectField: objectField?.label,
      isJs: isJsDisplayRule ? isJsDisplayRule : false,
      jsSetting: jsSettingMap,
      displayRuleValues,
    })
      .unwrap()
      .then(res => {
        if (id) {
          return
        }

        if (!res) {
          handleCancel()
        }

        navigate(`${ROUTES.CONFIG_DISPLAY_RULES_EDIT}/${res.id}`)
      })
      .finally(() => setSkipDirty(false))
      .catch(() => {
        //
      })
  })

  const {
    state: { allDisplayRules },
  } = useDisplayRules()

  const handleCloseModal = (hasDirty: boolean) => {
    if (hasDirty && !confirm(t('notifications.leave'))) {
      return
    }
    setShowDialog(false)
  }

  const handleSetShowDialog = (show: boolean) => {
    setShowDialog(show)
  }

  const handleCancel = () => {
    if (isDirty && !confirm(t('notifications.leave'))) {
      return
    }
    navigate(ROUTES.CONFIG_DISPLAY_RULES)
  }

  const handleOpenDialog = async (type: ModalType, id?: number | string) => {
    if (type === MODAL_TYPE.EDIT && id) {
      const editableField = fields.find(field => id === field.id)
      if (editableField) {
        setEditableFieldValue(editableField)
      }
    }
    setModalType(type)
    setShowDialog(true)
  }

  const handleDeleteDisplayRuleValue = (id: number | string) => {
    const filteredFields = fields.filter(field => field.id !== id)
    setFields(filteredFields)
    setDirtyOnField(!isEqual(initFields, filteredFields))
  }

  const handleEdit = (id?: number | string) => {
    setDisplayRuleValueID(Number(id))
    if (id) {
      handleOpenDialog(MODAL_TYPE.EDIT, id)
    }
  }

  return {
    state: {
      showDialog,
      modalType,
      fieldsSelectedObject,
      displayRuleValueID,
      showScriptValueDialogTitle,
      rows: fields,
      editableFieldValue,
      isDirty,
      dirtyOnField,
      allDisplayRules,
    },
    data: {
      objects,
      watch,
      watchIsJsDisplayRule,
      watchObject,
      watchStylesCodes,
      methods,
      selectedObject,
      displayRuleId,
      displayRuleById,
      isLoadingDisplayRuleById,
      styleSettingScriptValue,
      isDirtyJsField,
      styleSettingsData,
      watchValueJs,
    },
    handlers: {
      handleSave,
      handleCancel,
      handleCloseModal,
      handleOpenDialog,
      handleEdit,
      handleDeleteDisplayRuleValue,
      handleSetShowScriptValueDialogTitle,
      setValue,
      handleSetFields,
      handleSetShowDialog,
    },
  }
}
