import { ReactNode, useContext } from 'react'
import i18next, { t } from 'i18next'
import { FormInputProps } from '@microservices/wiskey-react-components'
import { DoneAll } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import { PageContext } from '@pages/EntityCreateOrEdit'

import { checkValidJS, generateDefaultObjectByFields, getObjectValueName } from '@helpers'
import {
  BIND_TYPE,
  FIELD_VALUE_TYPE,
  GENERATOR_INPUT_TYPE,
  MAX_INPUT_LENGTH,
  MODAL_TYPE,
  OBJECT_FIELD_TYPE,
  PINNED_COLUMN,
  REGEX,
} from '@constants'
import { AutocompleteOption, SelectOption } from '@types'

import { ObjectValueOptionsType } from './useAddColumnDialog'

export type ColumnForm = {
  id?: number | string
  code: string
  title: string
  bindType?: BIND_TYPE
  value: string
  objectValue?: string
  valueType: FIELD_VALUE_TYPE | null
  columnToSort: AutocompleteOption | null
  order?: string | number
  pinning: boolean
  useParameters: boolean
  pinnedColumn: AutocompleteOption | null
  enumDescription?: boolean
  useDisplayRule?: boolean
  asCheckbox: boolean
  asDuration: boolean
  displayRule?: AutocompleteOption | null
  displayRuleId?: number | undefined
}

type useInputsParams = {
  watchBinding: BIND_TYPE | undefined
  watchValue: string
  watchObjectValue: string | undefined
  handleChangeValue: (value: string | number) => void
  valueOptions: ObjectValueOptionsType[]
  bindTypeOptions: SelectOption[]
  handleChangeBinding: (value: string | number) => void
  toggleOpenScriptValueDialog: (open: boolean) => void
  valueType: FIELD_VALUE_TYPE | null
  isObjectLinkedValue: boolean
  objectValueOptions: ObjectValueOptionsType[]
  watchPinning: boolean
  watchAsCheckbox: boolean
  watchAsDuration: boolean
  fieldType: OBJECT_FIELD_TYPE | undefined | null
  watchUseDisplayRule?: boolean
  autocompleteOptionsDisplayRules?: AutocompleteOption | null
  isLoadingDisplayRules?: boolean
}

export const useInputs = ({
  watchBinding,
  watchObjectValue,
  watchValue,
  handleChangeValue,
  valueOptions,
  bindTypeOptions,
  handleChangeBinding,
  toggleOpenScriptValueDialog,
  valueType,
  isObjectLinkedValue,
  objectValueOptions,
  watchPinning,
  watchAsCheckbox,
  watchAsDuration,
  fieldType,
  watchUseDisplayRule,
  autocompleteOptionsDisplayRules,
  isLoadingDisplayRules,
}: useInputsParams) => {
  const {
    currentRow,
    modalType,
    rawObjectFields,
    mainTable: { rows: columns },
  } = useContext(PageContext)

  const srcObj = generateDefaultObjectByFields(rawObjectFields || [])

  const isDisabledPinning: boolean = columns
    .filter(col => col.id !== currentRow?.id)
    .every(col => col.pinnedColumn)

  const showEnumDescription: boolean =
    Boolean(
      columns.find(col => col.id === currentRow?.id && col.fieldType === OBJECT_FIELD_TYPE.ENUM)
    ) ||
    (fieldType === OBJECT_FIELD_TYPE.ENUM && modalType === MODAL_TYPE.CREATE)

  const valueInput: FormInputProps =
    watchBinding === BIND_TYPE.JS || watchBinding === BIND_TYPE.JSX
      ? {
          name: 'value',
          inputType: GENERATOR_INPUT_TYPE.TEXTAREA,
          placeholder: i18next.t('columnInputs.value.fieldPlaceholder'),
          label: i18next.t('columnInputs.value.label'),
          maxLengthInput: MAX_INPUT_LENGTH,
          readOnly: true,
          rows: 10,
          rules: {
            required: true,
            maxLength: MAX_INPUT_LENGTH,
            validate: value => {
              if (checkValidJS(value, { srcObj }).error && watchBinding === BIND_TYPE.JS) {
                return `${t('error.valueJS')}`
              }
            },
          },
          additionalBtn: {
            isEnabled: true,
            text: 'edit',
            color: 'primary',
            variant: 'contained',
            onClick: () => toggleOpenScriptValueDialog(true),
          },
          onChangeSelect: handleChangeValue,
        }
      : {
          name: 'value',
          inputType: GENERATOR_INPUT_TYPE.SELECT,
          label: i18next.t('columnInputs.value.label'),
          placeholder: i18next.t('placeholder.value'),
          disabled: !watchBinding || watchBinding === i18next.t('placeholder.bindType'),
          selectOptions: valueOptions,
          renderOptionSelect: (option: ObjectValueOptionsType) => {
            return option.isAlreadyUsed ? (
              <>
                <DoneAll sx={{ fontSize: '0.9rem', position: 'absolute', left: 2 }} />
                <Typography ml={1}>
                  {getObjectValueName(option.name, option.fieldType, option.isPk)}
                </Typography>
              </>
            ) : (
              getObjectValueName(option.name, option.fieldType, option.isPk)
            )
          },
          ...(watchValue !== i18next.t('placeholder.value') && {
            renderValueSelect: value => {
              const option = valueOptions.find(el => el.name === value)

              if (option) {
                const nameWithPostfix = getObjectValueName(
                  option.name,
                  option.fieldType,
                  option.isPk
                )

                return (
                  <Box title={option.name !== nameWithPostfix ? nameWithPostfix : undefined}>
                    {value as ReactNode}
                  </Box>
                )
              }

              return value
            },
          }),
          MenuProps: { PaperProps: { sx: { maxHeight: 300 } } },
          rules: { required: true, validate: value => value !== i18next.t('placeholder.value') },
          onChangeSelect: handleChangeValue,
        }

  const objectValue: FormInputProps = isObjectLinkedValue
    ? [
        {
          name: 'objectValue',
          inputType: GENERATOR_INPUT_TYPE.SELECT,
          placeholder: t('placeholder.objectValue'),
          label: t('label.objectValue'),
          selectOptions: objectValueOptions,
          MenuProps: { PaperProps: { sx: { maxHeight: 300 } } },
          disabled: objectValueOptions.length === 0,
          renderOptionSelect: (option: ObjectValueOptionsType) => {
            return option.isAlreadyUsed ? (
              <>
                <DoneAll sx={{ fontSize: '0.9rem', position: 'absolute', left: 2 }} />
                <Typography ml={1}> {option.name}</Typography>
              </>
            ) : (
              option.name
            )
          },
          ...(watchObjectValue !== t('placeholder.objectValue') && {
            renderValueSelect: value => value,
          }),
          rules: {
            validate: (value: string) => {
              if (
                value === i18next.t('placeholder.objectValue') &&
                objectValueOptions.length !== 0
              ) {
                return false
              }
            },
            required: true,
          },
        },
      ]
    : []

  const shouldDisplayAsCheckboxField =
    (valueType === FIELD_VALUE_TYPE.INTEGER || valueType === FIELD_VALUE_TYPE.BOOLEAN) &&
    fieldType !== OBJECT_FIELD_TYPE.ENUM

  const columnInputs: FormInputProps[] = [
    {
      name: 'code',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: i18next.t('columnInputs.code.placeholder'),
      label: i18next.t('columnInputs.code.label'),
      disabled: modalType === MODAL_TYPE.EDIT,
      replacePattern: REGEX.MODEL_CODE_REPLACE_PATTERN,
      rules: {
        required: true,
        validate: value => {
          if (columns.find(col => col.code === value && currentRow?.code !== value)) {
            return `${value} ${i18next.t('columnInputs.errors.alreadyUsed')}`
          }
        },
      },
    },
    {
      name: 'title',
      inputType: GENERATOR_INPUT_TYPE.INPUT,
      placeholder: i18next.t('columnInputs.title.placeholder'),
      label: i18next.t('columnInputs.title.label'),
    },
    {
      name: 'bindType',
      inputType: GENERATOR_INPUT_TYPE.SELECT,
      placeholder: i18next.t('placeholder.bindType'),
      label: i18next.t('columnInputs.bindType.label'),
      selectOptions: bindTypeOptions,
      rules: { required: true, validate: value => value !== i18next.t('placeholder.bindType') },
      onChangeSelect: handleChangeBinding,
    },
    valueInput,
    ...(watchBinding !== BIND_TYPE.JS && watchBinding !== BIND_TYPE.JSX
      ? [
          {
            name: 'valueTypeRow',
            inputs: [
              {
                name: 'valueType',
                inputType: GENERATOR_INPUT_TYPE.INPUT,
                label: t('label.valueType'),
                placeholder: t('placeholder.valueType'),
                disabled: true,

                value: valueType,
              },
              ...(shouldDisplayAsCheckboxField
                ? [
                    {
                      name: 'asCheckbox',
                      inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
                      label: t('label.asCheckbox'),
                      labelPlacement: 'end',
                      disabled: !!watchAsDuration,
                    } as FormInputProps,
                  ]
                : []),
              ...(valueType === FIELD_VALUE_TYPE.INTEGER
                ? [
                    {
                      name: 'asDuration',
                      inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
                      label: t('label.asDuration'),
                      labelPlacement: 'end',
                      disabled: !!watchAsCheckbox,
                    } as FormInputProps,
                  ]
                : []),
            ],
          },
        ]
      : []),
    ...objectValue,
    {
      name: 'columnToSort',
      inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
      placeholder: i18next.t('placeholder.columnToSort'),
      disabled:
        watchBinding === i18next.t('placeholder.bindType') || watchBinding === BIND_TYPE.FIELD,
      label: i18next.t('columnInputs.columnToSort.label'),
      isOptionEqualToValue: (option, value) => option.label === value.label,
      autocompleteOptions: rawObjectFields?.map(obj => ({ id: obj.id, label: obj.name })) ?? [],
    },
    {
      name: 'pinning',
      inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
      label: i18next.t('columnInputs.pinning.label'),
      labelPlacement: 'end',
      disabled: isDisabledPinning,
      labelSx: { minWidth: 'auto', maxWidth: 100 },
    },
    {
      name: 'useDisplayRule',
      inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
      label: i18next.t('columnInputs.useDisplayRule.label'),
      labelPlacement: 'end',
      labelSx: { width: 160 },
    },
    {
      name: 'useParameters',
      inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
      label: i18next.t('columnInputs.useParameters.label'),
      labelPlacement: 'end',
    },
    ...(watchPinning
      ? [
          {
            name: 'pinnedColumn',
            inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
            label: i18next.t('columnInputs.pinnedColumn.label'),
            rules: { required: true },
            autocompleteOptions: Object.values(PINNED_COLUMN).map(value => ({
              id: value,
              label: value,
            })),
          },
        ]
      : []),
    showEnumDescription
      ? {
          name: 'enumDescription',
          inputType: GENERATOR_INPUT_TYPE.CHECKBOX,
          label: i18next.t('columnInputs.enumDescription.label'),
          labelPlacement: 'end',
          labelSx: { width: 'auto' },
        }
      : {},
    ...(watchUseDisplayRule
      ? [
          {
            name: 'displayRule',
            inputType: GENERATOR_INPUT_TYPE.AUTOCOMPLETE,
            loading: isLoadingDisplayRules,
            label: i18next.t('columnInputs.displayRule.label'),
            rules: { required: true },
            autocompleteOptions: autocompleteOptionsDisplayRules,
          },
        ]
      : []),
  ]

  return columnInputs
}
