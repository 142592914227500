import { FC } from 'react'
import { Popper, SxProps, Theme } from '@mui/material'

import NestedMenu, { CursorPosition, NestedMenuProps } from '@components/NestedMenu'

export const sxNestedMenu: SxProps<Theme> = {
  bgcolor: theme => theme.palette.background.default,
  zIndex: theme => theme.zIndex.tooltip + 1,
  boxShadow: '1px 1px 3px black',
  border: '1px solid gray',
  '& .MuiList-root': {
    py: 0,
  },
  '& .MuiListItemButton-root': {
    py: '3px',
    pl: '40px',
    pr: '15px',
    '&:hover': {
      bgcolor: theme => theme.palette.background.hovered,
    },
    bgcolor: theme => theme.palette.background.default,
  },
  '& .MuiDivider-root': {
    mr: '4px',
    my: '3px',
  },
}

export const PopperContextMenu: FC<
  Omit<NestedMenuProps, 'anchorEl'> & {
    anchorEl: HTMLElement | Element | null | undefined
    position: CursorPosition | null
    onRefContextMenu: any
  }
> = ({ anchorEl, position, onClose, onRefContextMenu, ...props }) => {
  return (
    <Popper
      ref={onRefContextMenu}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      placement='bottom-start'
      sx={{
        ...(position && {
          transform: `translate(${position?.left}px, ${position?.top}px) !important`,
        }),
        ...sxNestedMenu,
      }}
    >
      <NestedMenu onClose={onClose} {...props} />
    </Popper>
  )
}
