import { LegacyRef, useEffect, useRef } from 'react'

export const useOutsideClickScrollRef = (
  onOutsideClickScroll: () => void
): LegacyRef<HTMLDivElement> | undefined => {
  const ref = useRef()

  useEffect(() => {
    const listener = (event: Event) => {
      if (
        !ref?.current ||
        ref?.current.contains(event.target) ||
        // для предотвращения "мерцания" попера при его вставки около нижней границы view
        event?.srcElement?.className === 'contract-trigger'
      ) {
        return
      }

      onOutsideClickScroll()
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('scroll', listener, true)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.addEventListener('scroll', listener, true)
    }
  }, [onOutsideClickScroll])

  return ref
}
