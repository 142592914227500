import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { matchPath, useLocation } from 'react-router-dom'

import { useFetchAllDisplayRulesQuery, useFetchAllObjectQuery } from '@redux/api'

import { useDebounce } from '@hooks'
import { ROUTES, SEARCH_PATTERN_DELAY } from '@constants'
import { AutocompleteOption, ShortDisplayRule } from '@types'

import { useHandlers } from './useHandlers'

type FormValues = {
  searchPattern: string
  selectedObject: AutocompleteOption<string> | null
}

const defaultValues = {
  searchPattern: '',
  selectedObject: null,
}

export const useDisplayRules = () => {
  const [allDisplayRules, setAllDisplayRules] = useState<ShortDisplayRule[]>([])
  const methods = useForm<FormValues>({ defaultValues })
  const { watch } = methods
  const watchSearchPattern = watch('searchPattern')
  const watchSelectedObject = watch('selectedObject')

  const debouncedSearchPattern = useDebounce(watchSearchPattern, SEARCH_PATTERN_DELAY)

  const { state, handlers } = useHandlers()

  const { data: objects } = useFetchAllObjectQuery()

  const { data: displayRulesData, isFetching: isLoadingDisplayRules } =
    useFetchAllDisplayRulesQuery(
      {
        searchPattern: debouncedSearchPattern ? debouncedSearchPattern : undefined,
        objectCode: watchSelectedObject?.id ? watchSelectedObject?.id : undefined,
      },
      {
        refetchOnMountOrArgChange: true,
      }
    )

  useEffect(() => {
    if (displayRulesData) {
      setAllDisplayRules(displayRulesData)
    }
  }, [displayRulesData])

  const { pathname } = useLocation()

  const isAllDisplayRules = matchPath(ROUTES.CONFIG_DISPLAY_RULES, pathname)

  return {
    state: {
      ...state,
      allDisplayRules,
      methods,
    },
    data: {
      isAllDisplayRules,
      objects,
      isLoadingDisplayRules,
    },
    handlers: {
      ...handlers,
    },
  }
}
