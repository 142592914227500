import { FIELD_VALUE_TYPE } from '@constants'
import { ObjectFieldDTO } from '@types'

export const getFilterByValueType = (
  field: string,
  value: string,
  type: FIELD_VALUE_TYPE,
  objLinkField: ObjectFieldDTO | undefined
) => {
  const fullSearchPattern = `${field}==${value}`
  const partialSearchPattern = `${field}=='${value}*'`

  // Фильтр с '*' не работает по obj_pk_link полям (_id)
  if (field === objLinkField?.name) {
    return objLinkField.isPk ? fullSearchPattern : partialSearchPattern
  }

  switch (type) {
    case FIELD_VALUE_TYPE.INTEGER:
      return fullSearchPattern
    default:
      return partialSearchPattern
  }
}
