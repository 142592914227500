import {
  BIND_TYPE,
  ENTITY_TYPES_REQUEST,
  FIELD_VALUE_FORMAT,
  FIELD_VALUE_TYPE,
  OBJECT_FIELD_TYPE,
  PINNED_COLUMN,
} from '../constants'

import { ViewActionRow } from './action'
import { ColumnParametersType, GETTabDTO } from './api'
import { DisplayRule } from './displayRule'

export type sortRestrictionType = {
  sortDirection: string
  columnToSort: string
  priority?: string // число
}

/** Ограничения поля, на котором основана колонка */
export type ColumnRestrictions = {
  /** Отображение колонки с данным полем у пользователя */
  show: boolean
  /** Ширина колонки с данным полем у пользователя */
  width: number
  /** Порядок колонки с данным полем в таблице у пользователя */
  order: number
  /** Закрепление колонки справа/слева в таблице у пользователя */
  fix: PINNED_COLUMN | null
  /** Примененная к колонке сортировка */
  sort: sortRestrictionType
}

export type ViewRow = {
  id: string | number
  order?: number
  code: string
  title: string
  valueType: FIELD_VALUE_TYPE | null
  bindType?: BIND_TYPE
  fieldType?: OBJECT_FIELD_TYPE
  value: string
  objectCode?: string
  objectValue?: string
  columnToSort?: string
  pinnedColumn: PINNED_COLUMN | null
  visibility?: boolean
  parameters?: ColumnParametersType
  restrictions?: ColumnRestrictions
  useLocalParameters?: boolean
  enumDescription?: boolean
  useDisplayRule?: boolean
  displayRule?: DisplayRule | undefined
  displayRuleId?: number | undefined
  valueFormat: FIELD_VALUE_FORMAT | null
  columnObjectValue?: string | undefined
}

export type EnumCellType = {
  name: string | undefined
  valueType: FIELD_VALUE_TYPE | null
  objectCode: string | undefined
  value: number | boolean | string
  isDialogWindow: boolean
  displayRule?: DisplayRule | undefined
  useDisplayRule?: boolean
}

export type ViewActionObjectParams = {
  formCode: string
  formCodeByFieldValue: FormCodeByFieldValueType[]
}

export type FormCodeByFieldValueType = { field: string; value: string; formCode: string }

export enum ENTITY_COMMAND_TYPE {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  GET_ALL = 'GET_ALL',
  GET_BY_ID = 'GET_BY_ID',
  GET_ALL_BY_IDS = 'GET_ALL_BY_IDS',
  GET_BY_INTERNAL_ID = 'GET_BY_INTERNAL_ID',
  GET_ALL_BY_INTERNAL_IDS = 'GET_ALL_BY_INTERNAL_IDS',
}

export type EntityCommandDTO = {
  type: ENTITY_COMMAND_TYPE
  name: string
  prepareScript?: string
}

export type EntityType = {
  id: string | number
  code: string
  title: string
  objectCode: string
  objectTitle: string
  columns?: ViewRow[]
  tabs?: GETTabDTO[]
  actions?: ViewActionRow[]
  commands: EntityCommandDTO[]
  rowBinding?: boolean
  rowBindingType?: string
  rowBindingValue?: string
  rowBindingValueTypeCode?: string
  auditFieldHistory?: boolean
  parameters?: ColumnParametersType
  useLocalParameters?: boolean
  type: ENTITY_TYPES_REQUEST
  hasQuickSearch?: boolean
  prefilter?: string
}
