import {
  createContext,
  forwardRef,
  MouseEvent,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { AutocompleteOption, EntityType, ObjectDataRecord, POSTObjectDataFilter } from 'src/types'
import { Button } from '@microservices/wiskey-react-components'
// import {
//   DataGrid,
//   GridHeaderWithScrollableDragging,
//   GridLoadingOverlay,
//   MuiEvent,
// } from '@microservices/wiskey-react-components/DataGrid'
import {
  DataGrid,
  GridHeaderWithScrollableDragging,
  GridLoadingOverlay,
  MuiEvent,
} from '@microservices/wiskey-react-components'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import {
  Box,
  Collapse,
  GlobalStyles,
  Grid,
  IconButton,
  IconButtonProps,
  Skeleton,
  styled,
  Typography,
} from '@mui/material'
import { DataGridProps } from '@mui/x-data-grid'

import { CustomColumnMenuComponent } from '@pages/ConfiguredEntity/components/CustomColumnMenuComponent'
import { CustomPanelComponent } from '@pages/ConfiguredEntity/components/CustomPanelComponent'
import { CustomToolbarComponent } from '@pages/ConfiguredEntity/components/CustomToolbarComponent'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { AppLoader } from '@components/AppLoader'
import { JumpButtonToConfig } from '@components/JumpButtonToConfig/JumpButtonToConfig'
import { PageTitle } from '@components/PageTitle'
import { PopperContextMenu } from '@components/PopperContextMenu'
import { QuickSearch } from '@components/QuickSearch'
import { QuickSearchInDDW } from '@components/QuickSearchInDDW'

import { buttonStylesByMode } from '@helpers'
import {
  CLICK_EVENT_TYPE,
  DATAGRID_HEADER_WINDOW_HEIGHT,
  DATAGRID_ROW_WINDOW_HEIGHT,
  DIALOG_WINDOW_BOTTOM_ACTION_BAR,
  DIALOG_WINDOW_HEADER_HEIGHT,
  ENTITY,
  HEIGHT_EMPTY_TABLE_SPACE,
  QUICK_SEARCH_DDW,
  SEARCH_ASSISTANT_VIEW,
  VIEW_ROWS_AMOUNT,
  VISIBLE_HIDDEN,
} from '@constants'

import { CustomNoRowsOverlay } from './components/CustomNoRowsOverlay'
import { RowStylesWrapper } from './components/RowStylesWrapper'
import { DynamicForms, StaticForms } from './components/SamePageForms'
import { useConfiguredEntity } from './hooks'
import { OpenFormProps, PageContextType } from './types'

export type ConfiguredEntityProps = {
  windowHeight?: number
  entityCode: string | null
  entityId: number | string | null
  path: string
  title: string
  isNestedView?: boolean
  parentFormIsDirty?: boolean
  getLeaveForm?: () => boolean
  onResetEditForm?: () => void
  onSelectPickerOption?: (row: ObjectDataRecord) => void
  pageSize?: number
  type: ENTITY
  formObjectId?: string
  formObjectCode?: string
  formElementId?: number
  valueId?: number
  fieldId?: number
  selectedPickerOption?: string
  selectedPickerOptionValue?: string | AutocompleteOption
  onMultiSelectPickerOption?: (value: AutocompleteOption[]) => void
  multipleSelectPickerOption?: AutocompleteOption[]
  objectValueNameOption?: string
  handleOpenForm?: (
    formProps: OpenFormProps,
    event: MouseEvent<HTMLButtonElement> | MuiEvent<MouseEvent>,
    type: CLICK_EVENT_TYPE
  ) => void
  search?: string
  pathname?: string
  enableStaticForms?: boolean
  dialogId?: string
  isListControlDialogWindow?: boolean
  isDropDownDialogWindow?: boolean
  isSearchAssistantDialogWindow?: boolean

  onSetEntity: (value: EntityType | null) => void
  entity: EntityType | null
  isViewDialogWindow?: boolean
  readonly?: boolean
}

export const PageContext = createContext<PageContextType>({} as PageContextType)

type ExpandMoreProps = {
  expand: boolean
} & IconButtonProps

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props

  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export type GetConfiguredEntityParamsHandle = {
  resetObjectData: (body: POSTObjectDataFilter) => void
}

export const ConfiguredEntity = forwardRef<GetConfiguredEntityParamsHandle, ConfiguredEntityProps>(
  (
    {
      windowHeight: propsWindowHeight = 0,
      entityCode,
      entityId,
      path,
      title,
      isNestedView = false,
      parentFormIsDirty,
      getLeaveForm,
      onResetEditForm,
      onSelectPickerOption,
      pageSize: defaultPageSize = VIEW_ROWS_AMOUNT,
      type,
      formObjectCode,
      formObjectId,
      formElementId,
      fieldId,
      valueId,
      selectedPickerOption,
      selectedPickerOptionValue,
      onMultiSelectPickerOption,
      multipleSelectPickerOption,
      objectValueNameOption,
      handleOpenForm,
      search,
      pathname = '',
      enableStaticForms,
      dialogId,
      isListControlDialogWindow = false,
      isDropDownDialogWindow = false,
      isSearchAssistantDialogWindow = false,
      onSetEntity,
      entity,
      isViewDialogWindow = false,
      readonly,
    },
    ref
  ) => {
    const { t } = useTranslation()

    const containerRef = useRef<HTMLDivElement>(null)
    const topEntityContentRef = useRef<HTMLDivElement>(null)

    const windowHeight = useMemo(
      () => propsWindowHeight || containerRef?.current?.clientHeight || 0,
      [containerRef.current?.clientHeight, propsWindowHeight]
    )

    const TOP_ENTITY_CONTENT_HEIGHT_VIEW = isViewDialogWindow
      ? (topEntityContentRef.current?.offsetHeight || 0) +
        DATAGRID_HEADER_WINDOW_HEIGHT +
        SEARCH_ASSISTANT_VIEW +
        DIALOG_WINDOW_HEADER_HEIGHT -
        HEIGHT_EMPTY_TABLE_SPACE
      : (topEntityContentRef.current?.offsetHeight || 0) + DATAGRID_HEADER_WINDOW_HEIGHT

    const TOP_ENTITY_CONTENT_HEIGHT_DROPDOWN =
      DIALOG_WINDOW_HEADER_HEIGHT +
      DATAGRID_HEADER_WINDOW_HEIGHT +
      DIALOG_WINDOW_BOTTOM_ACTION_BAR +
      QUICK_SEARCH_DDW

    const TOP_ENTITY_CONTENT_HEIGHT = isDropDownDialogWindow
      ? TOP_ENTITY_CONTENT_HEIGHT_DROPDOWN
      : TOP_ENTITY_CONTENT_HEIGHT_VIEW

    const pageSizeByWindowHeight = useMemo(
      () =>
        windowHeight
          ? Math.floor((windowHeight - TOP_ENTITY_CONTENT_HEIGHT) / DATAGRID_ROW_WINDOW_HEIGHT)
          : 0,
      [TOP_ENTITY_CONTENT_HEIGHT, windowHeight]
    )

    const customPageSize = useMemo(
      () => (pageSizeByWindowHeight > defaultPageSize ? pageSizeByWindowHeight : defaultPageSize),
      [defaultPageSize, pageSizeByWindowHeight]
    )

    const tableHeight = useMemo(
      () =>
        pageSizeByWindowHeight > defaultPageSize
          ? windowHeight - TOP_ENTITY_CONTENT_HEIGHT
          : undefined,
      [TOP_ENTITY_CONTENT_HEIGHT, defaultPageSize, pageSizeByWindowHeight, windowHeight]
    )

    const { state, data, handlers } = useConfiguredEntity({
      entityCode,
      entityId,
      path,
      isNestedView,
      getLeaveForm,
      onResetEditForm,
      onSelectPickerOption,
      parentFormIsDirty,
      type,
      formObjectCode,
      formObjectId,
      formElementId,
      fieldId,
      valueId,
      selectedPickerOption,
      selectedPickerOptionValue,
      onMultiSelectPickerOption,
      multipleSelectPickerOption,
      objectValueNameOption,
      handleOpenForm,
      search,
      pathname,
      dialogId,
      isListControlDialogWindow,
      customPageSize,
      onSetEntity,
      entity,
      readonly,
      isViewDialogWindow,
    })

    const {
      isCtrlHeld,
      isShiftHeld,
      selectedRowIds,
      samePageFormsData,
      currentStaticForm,
      currentDynamicForm,
      globalStyles,
      isListControl,
      columnVisibilityModel,
      showActionsColumn,
      columnNameCheckBox,
      visibleColumns,
      sortKeysForQuickSearch,
      sortKeysColumnCheckBox,
      pinnedColumns,
      scrollingParams,
      viewColumns,
      isLoadingRestrictions,
      isBooleanSearchMode,
      configActions,
      contextMenuData,
      onRefContextMenu,
      anchorEl,
      position,
      objectStyles,
    } = state

    const {
      isAssistiantSearch,
      objects,
      form,
      formIsSuccess,
      actions,
      objectCode,
      allViews,
      columns,
      isForm,
      isSearchResult,
      rowCreateAction,
      hasRowAction,
      shouldDisplayCreateButton,
      currentSort,
      isLoadingView,
      objectData,
      totalPages,
      totalElements,
      isLoadingObjectData,
      apiRef,
      currentPage,
      staticFormsData,
      dynamicFormsData,
      areAllColumnsHidden,
      isColumnReorderEnabled,
      valueSearchFilter,
      searchRule,
      hasQuickSearch,
    } = data

    const {
      handleChangeModelSort,
      handleClickSearchFilter,
      handleSetSelectedRowIds,
      handleStaticFormChange,
      handleDynamicFormChange,
      handleRemoveSamePageForm,
      handleCloseCurrentFormTab,
      handleSetOpenedContainersInStaticForms,
      handleRowClick,
      handleCellClick,
      handleCreate,
      handleGetRowClassName,
      handleObjectDataPageChange,
      handleSetObjectData,
      handleGetCellClassName,
      handleColumnWidthChange,
      handleUpdateSamePageFormData,
      handleOpenShowColumnsSettings,
      handlePinnedColumnsChange,
      handleDeleteViewRestrictionsByType,
      handleChangeColumnVisibilityModel,
      handleSetGlobalFilter,
      handleSetSearchRule,
      handleSetColumnNameCheckBox,
      handleSetSortKeysColumnCheckBox,
      handleSetBooleanMode,
      handleContextMenu,
      onCloseContextMenu,
    } = handlers

    useImperativeHandle(ref, () => ({
      resetObjectData: handleClickSearchFilter,
    }))

    const contextValue: PageContextType = {
      isBooleanSearchMode,
      columnNameCheckBox,
      sortKeysForQuickSearch,
      sortKeysColumnCheckBox,
      searchRule,
      visibleColumns,
      entityCode,
      entityId,
      currentSort: currentSort || [],
      columns,
      path,
      rows: objectData,
      title: title || '',
      countPage: totalPages,
      actions: actions ?? [],
      isLoadingObjectData,
      isLoadingView,
      form: form || null,
      formIsSuccess,
      objectCode,
      apiRef,
      type,
      allViews: allViews?.data,
      isAssistiantSearch,
      objects: objects || [],
      isCtrlHeld,
      isShiftHeld,
      samePageFormsData,
      selectedRowIds,
      currentStaticForm,
      currentDynamicForm,
      staticFormsData,
      dynamicFormsData,
      showActionsColumn,
      hasRowAction,
      isViewDialogWindow,
      dialogId,
      valueSearchFilter,
      scrollingParams,
      onSetSorting: handleChangeModelSort,
      handleRemoveSamePageForm,
      handleUpdateSamePageFormData,
      onSetOpenedContainersInStaticForms: handleSetOpenedContainersInStaticForms,
      onSetAllObjectData: handleSetObjectData,
      onSetSelectedRowId: handleSetSelectedRowIds,
      handleCloseCurrentFormTab,
      handleStaticFormChange,
      handleDynamicFormChange,
      handleSetBooleanMode,
      handleSetGlobalFilter,
      onDeleteViewRestrictionsByType: handleDeleteViewRestrictionsByType,
    }

    const CreateButton = () => {
      return (
        <Button
          variant='text'
          sx={theme => ({
            ...buttonStylesByMode(theme, isViewDialogWindow || isListControlDialogWindow),
            zIndex: 2,
            mr: 0.5,
          })}
          onClick={handleCreate}
        >
          {rowCreateAction?.title}
        </Button>
      )
    }

    const [expanded, setExpanded] = useState(true)

    const Title = () =>
      useMemo(
        () =>
          type === ENTITY.LIST_CONTROL ? (
            <Typography sx={{ fontSize: '1rem' }}>
              {title}: {totalElements || 0}
            </Typography>
          ) : (
            <Box>{title}</Box>
          ),
        [type, title, totalElements, expanded]
      )

    const columnEditingComponents: Pick<DataGridProps, 'components'> = {
      components: {
        ColumnMenu: props => (
          <CustomColumnMenuComponent {...props} onShowConfig={handleOpenShowColumnsSettings} />
        ),
        Toolbar: () => (
          <CustomToolbarComponent
            isAssistantSearch={isAssistiantSearch}
            showColumnsButton={areAllColumnsHidden as boolean}
          />
        ),
        Panel: CustomPanelComponent,
      },
    }

    const userSettingsComponents =
      type === ENTITY.VIEW || type === ENTITY.LIST_CONTROL ? columnEditingComponents : {}

    const isCreateButton = isSearchResult && shouldDisplayCreateButton && isAssistiantSearch
    const hasScrollbar = objectData.length > customPageSize
    const styles = { ...globalStyles, ...objectStyles }

    return (
      <PageContext.Provider value={contextValue}>
        {(configActions.actionsAvailable.on_column_right_click ||
          configActions.actionsAvailable.on_column_right_click) && (
          <PopperContextMenu
            anchorEl={anchorEl}
            dataTransfer={contextMenuData.dataTransfer}
            items={contextMenuData.items}
            position={position}
            onClose={onCloseContextMenu}
            onItemClick={contextMenuData.onItemClick}
            onRefContextMenu={onRefContextMenu}
          />
        )}
        <GlobalStyles styles={styles} />
        {type !== ENTITY.DROP_DOWN_ENTITY && (
          <Outlet
            context={{
              handleRemoveSamePageForm,
              handleUpdateSamePageFormData,
              handleCloseCurrentFormTab,
              entityType: type,

              onSetOpenedContainersInStaticForms: handleSetOpenedContainersInStaticForms,

              samePageFormsData,
              selectedRowIds,
            }}
          />
        )}
        <Box
          ref={containerRef}
          sx={{
            ...(isForm && !isNestedView && type !== ENTITY.DROP_DOWN_ENTITY && VISIBLE_HIDDEN),
            ...(isNestedView && { mb: 0 }),
            ...(isListControl && { cursor: 'pointer' }),
            ...(windowHeight && { height: 'calc(100% - 38px)' }),
          }}
        >
          {dynamicFormsData.length && hasRowAction && type !== ENTITY.DROP_DOWN_ENTITY ? (
            <DynamicForms />
          ) : (
            <></>
          )}
          {/* {isShiftHeld && <DynamicForms />} */}
          {type !== ENTITY.DROP_DOWN_ENTITY && (
            <PageTitle
              end={
                <Box mr={isViewDialogWindow ? 1 : 0.5}>
                  {isListControl && (
                    <ExpandMore expand={expanded} onClick={() => setExpanded(!expanded)}>
                      <ExpandMoreIcon />
                    </ExpandMore>
                  )}
                </Box>
              }
              sx={{
                margin: `${isViewDialogWindow ? '4px 0' : '16px 0 8px 0'}`,
                ...(isNestedView && { mb: 0 }),
                ...(isSearchAssistantDialogWindow && { m: 0 }),
              }}
              {...(isListControl && {
                onClick: () => setExpanded(!expanded),
                start: (
                  <Grid container direction={'column'}>
                    <Grid item mb={0} px='10px'>
                      <Typography variant={'h6'}>{title ? <Title /> : <Skeleton />}</Typography>
                    </Grid>
                  </Grid>
                ),
              })}
            />
          )}
          <PageContentLayout isBreadcrumbs hidden={isNestedView}>
            {isLoadingView && !entity ? (
              <AppLoader />
            ) : (
              <Collapse
                unmountOnExit
                in={expanded}
                timeout='auto'
                sx={{
                  position: 'relative',
                  ...(!isAssistiantSearch && type === ENTITY.VIEW && { top: '-38px' }),
                  ...(type === ENTITY.VIEW && { top: '-10px' }),
                }}
              >
                <Box
                  sx={{
                    p: isNestedView || type === ENTITY.DROP_DOWN_ENTITY ? 0 : 1,
                    pt: isAssistiantSearch ? 1 : 0,
                  }}
                >
                  <Box ref={topEntityContentRef}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      {type === ENTITY.DROP_DOWN_ENTITY && entity && (
                        <QuickSearchInDDW setGlobalFilter={handleSetGlobalFilter} />
                      )}
                      {type === ENTITY.DROP_DOWN_ENTITY && entity && (
                        <JumpButtonToConfig
                          code={entity?.code ?? entityCode}
                          entityType={type}
                          type={'entity'}
                        />
                      )}
                    </Box>
                    {type !== ENTITY.DROP_DOWN_ENTITY && (
                      <Grid container alignItems='center' justifyContent='space-between'>
                        <Grid item alignItems='center' display='flex'>
                          {hasQuickSearch && entity && (
                            <Box sx={{ mb: isViewDialogWindow ? 0 : 1, mt: 0 }}>
                              <QuickSearch
                                columnNameCheckBox={columnNameCheckBox}
                                columns={viewColumns}
                                dialogId={dialogId}
                                isBooleanSearchMode={isBooleanSearchMode}
                                isDialogWindow={isViewDialogWindow || isListControlDialogWindow}
                                loading={isLoadingObjectData}
                                scrollingParams={scrollingParams}
                                searchRule={searchRule}
                                searchValue={valueSearchFilter}
                                setBooleanMode={handleSetBooleanMode}
                                setColumnNameCheckBox={handleSetColumnNameCheckBox}
                                setGlobalFilter={handleSetGlobalFilter}
                                setSearchRule={handleSetSearchRule}
                                setSortKeysForQuickSearch={handleSetSortKeysColumnCheckBox}
                                sortKeysColumnCheckBox={sortKeysColumnCheckBox}
                              />
                            </Box>
                          )}
                          {(type === ENTITY.VIEW || isSearchResult) && (
                            <Typography fontSize='1rem' pb={isViewDialogWindow ? 0 : 1} pr={1}>
                              {t('searchAssistant.result', { totalElements })}
                            </Typography>
                          )}
                        </Grid>
                        {entity && (
                          <JumpButtonToConfig
                            code={entity?.code ?? entityCode}
                            entityType={type}
                            type={'entity'}
                          />
                        )}
                        <Grid item>
                          {((shouldDisplayCreateButton && !isAssistiantSearch) ||
                            isCreateButton) && <CreateButton />}
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                  <Grid container flexDirection={'column'} flexWrap={'nowrap'} spacing={2}>
                    <Grid item>
                      <RowStylesWrapper
                        currentPage={currentPage}
                        globalStyles={globalStyles}
                        hasScrollbar={hasScrollbar}
                        objectData={objectData}
                        pageSize={customPageSize}
                        type={type}
                        isDialogWindow={
                          isViewDialogWindow || isListControlDialogWindow || isDropDownDialogWindow
                        }
                      >
                        <DataGrid
                          {...((isViewDialogWindow ||
                            isListControlDialogWindow ||
                            isDropDownDialogWindow) && {
                            headerHeight: DATAGRID_HEADER_WINDOW_HEIGHT,
                          })}
                          {...((isViewDialogWindow ||
                            isListControlDialogWindow ||
                            isDropDownDialogWindow) && {
                            rowHeight: DATAGRID_ROW_WINDOW_HEIGHT,
                          })}
                          enableCellSeparator
                          enableMultiSort
                          hideFooter
                          apiRef={apiRef}
                          columnVisibilityModel={columnVisibilityModel}
                          columns={columns}
                          disableColumnMenu={type === ENTITY.DROP_DOWN_ENTITY}
                          disableColumnPinning={false}
                          disableColumnResize={false}
                          fetchNextPage={handleObjectDataPageChange}
                          getCellClassName={handleGetCellClassName}
                          getRowClassName={handleGetRowClassName}
                          loading={isLoadingView || isLoadingObjectData || isLoadingRestrictions}
                          pageCount={totalPages}
                          pageSize={customPageSize}
                          pinnedColumns={objectData.length > 0 ? pinnedColumns : undefined}
                          rowHoverCursor={hasRowAction ? 'pointer' : 'default'}
                          rows={objectData}
                          sortModel={currentSort}
                          sortingMode={'server'}
                          tableHeight={tableHeight}
                          visibilityBadgeSort={pathname.includes('forms') && !isNestedView}
                          componentsProps={{
                            row: {
                              onContextMenu: handleContextMenu,
                            },
                          }}
                          components={{
                            Header: GridHeaderWithScrollableDragging,
                            LoadingOverlay: GridLoadingOverlay,
                            NoRowsOverlay: CustomNoRowsOverlay,
                            ...userSettingsComponents.components,
                          }}
                          // dataGridBoxSx={
                          //   type === ENTITY.DROP_DOWN_ENTITY ? { height: tableHeight } : {}
                          // }
                          sx={{
                            '& .MuiDataGrid-menuIcon .MuiButtonBase-root, & .MuiDataGrid-iconButtonContainer .MuiButtonBase-root':
                              {
                                mt: '4px',
                              },
                            '& .MuiDataGrid-columnHeaders': {
                              '& .MuiButtonBase-root': {
                                padding: '3px',
                              },
                              '& .MuiDataGrid-menuIcon svg': {
                                fontSize: '1rem',
                              },
                              '& .MuiDataGrid-iconButtonContainer svg': {
                                fontSize: '0.85rem',
                              },
                            },
                            ...(objectData.length === 0
                              ? {
                                  '.MuiDataGrid-columnSeparator': { display: 'none' },
                                  row: {
                                    transition: '0.2s ease-in',
                                  },
                                  ...(areAllColumnsHidden && {
                                    '.MuiDataGrid-pinnedColumnHeaders, .MuiDataGrid-columnHeadersInner':
                                      { visibility: 'hidden' },
                                  }),
                                }
                              : {}),
                          }}
                          onCellClick={handleCellClick}
                          onColumnVisibilityModelChange={handleChangeColumnVisibilityModel}
                          onColumnWidthChange={handleColumnWidthChange}
                          onPinnedColumnsChange={handlePinnedColumnsChange}
                          onRowClick={handleRowClick}
                          onSortModelChange={handleChangeModelSort}
                          onRowDoubleClick={handleRowClick}
                          // disableColumnReorder={isNestedView}
                          disableColumnReorder={!isColumnReorderEnabled}
                        />
                      </RowStylesWrapper>
                    </Grid>
                    {enableStaticForms && staticFormsData.length && hasRowAction ? (
                      <Grid item>
                        <StaticForms />
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Box>
              </Collapse>
            )}
          </PageContentLayout>
          {enableStaticForms &&
          staticFormsData.length &&
          hasRowAction &&
          type !== ENTITY.DROP_DOWN_ENTITY ? (
            <Box sx={{ minHeight: 500 }} />
          ) : (
            <></>
          )}
        </Box>
      </PageContext.Provider>
    )
  }
)

ConfiguredEntity.displayName = 'ConfiguredEntity'
