import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { FormLabel, TextField } from '@microservices/wiskey-react-components'
import { LabelPlacement } from '@microservices/wiskey-react-components/dist/types'
import { Grid, SxProps, useTheme } from '@mui/material'

import { generalFieldStylesByMode } from '@helpers'

export type MaskedTimeInputForFormProps = {
  inputMaskProps: {
    outerValue: string
    setOuterValue: (value: string) => void
    disabled?: boolean
    placeholder: string
  }
  formRelatedProps: {
    dirtyFields?: Partial<Readonly<{ [x: string]: boolean }>>
    field?: string
    updateField?: boolean
    isDialogWindow?: boolean
    labelPosition: LabelPlacement
    label?: string
    labelWidthSx?: SxProps
    name: string
  }
}

export const MaskedTimeInputForForm: FC<MaskedTimeInputForFormProps> = ({
  inputMaskProps,
  formRelatedProps,
}) => {
  const theme = useTheme()

  const { control } = useFormContext()

  const {
    dirtyFields,
    field,
    updateField,
    isDialogWindow,
    labelPosition,
    label,
    labelWidthSx,
    name,
  } = formRelatedProps

  const { outerValue, setOuterValue, disabled, placeholder } = inputMaskProps

  const formLabelSx: SxProps = {
    '*': {
      transition: '0.2s ease-in',
    },
    '& > .MuiFormControl-root': {
      ...generalFieldStylesByMode(theme, dirtyFields, field, updateField, isDialogWindow),
      '& .MuiInputBase-root .MuiInputAdornment-root .MuiButtonBase-root': {
        padding: isDialogWindow ? 0 : 1,
      },
    },
  }

  const labelSx: SxProps = {
    '*': {
      transition: '0.2s ease-in',
    },
    fontWeight: 400,
    letterSpacing: 0.5,
    color: theme.palette.color.default,
  }

  const getMaskByValue = (value: string) => {
    const mask = '99:59'

    if (value.includes('+')) {
      return `${mask}+`
    }

    return mask
  }

  return (
    <Grid item justifyContent='space-between' sx={{ mb: 1 }}>
      <FormLabel
        label={label}
        labelPlacement={labelPosition}
        name={name}
        sx={formLabelSx}
        control={
          <Controller
            control={control}
            name={name}
            render={({ fieldState: { invalid } }) => {
              return (
                <InputMask
                  disabled={disabled}
                  mask={getMaskByValue(outerValue)}
                  maskChar='-'
                  value={outerValue}
                  formatChars={{
                    5: '[0-5]',
                    9: '[0-9]',
                  }}
                  onChange={(e: any) => setOuterValue(e.currentTarget?.value)}
                >
                  {() => (
                    <TextField
                      fullWidth
                      disabled={disabled}
                      error={invalid}
                      placeholder={placeholder}
                      size='small'
                      type='text'
                    />
                  )}
                </InputMask>
              )
            }}
          />
        }
        labelSx={{
          ...labelWidthSx,
          ...labelSx,
        }}
      />
    </Grid>
  )
}
