import { useParams } from 'react-router-dom'

import { useHandlers } from '@pages/DisplayRulesCreateOrEdit/hooks/useHandlers'

import { ROUTES } from '@constants'

export const useDisplayRulesCreateOrEdit = () => {
  const { id } = useParams()

  const { handlers, state, data } = useHandlers()

  const isCreate = ROUTES.CONFIG_DISPLAY_RULES_CREATE === location.pathname
  const isEdit = !!id

  return {
    data: {
      ...data,
      id,
      isCreate,
      isEdit,
    },
    state: { ...state },
    handlers: { ...handlers },
  }
}
