import i18next from 'i18next'
import * as yup from 'yup'

import {
  ConfigField,
  EmbeddedObject,
  GanttColumnForm,
  GanttFormValues,
  SectionType,
  TimelineItem,
} from '@gantt/components/GanttCreateOrEdit/types'

const requiredFieldPicker = yup.object().shape<Partial<Record<keyof ConfigField, yup.AnySchema>>>({
  pathStr: yup.string().test('required', '', val => Boolean(val?.trim())),
  pathArray: yup.array().of(
    yup.object().shape<Partial<Record<keyof EmbeddedObject, yup.AnySchema>>>({
      field: yup
        .string()
        .test('required', '', val => Boolean(val))
        .nullable(),
    })
  ),
})

const notRequiredFieldPicker = yup
  .object()
  .shape<Partial<Record<keyof ConfigField, yup.AnySchema>>>({
    pathArray: yup.array().of(
      yup.object().shape<Partial<Record<keyof EmbeddedObject, yup.AnySchema>>>({
        field: yup
          .string()
          .test('required', '', val => Boolean(val))
          .nullable(),
      })
    ),
  })

export const ganttColumnFormSchema = yup
  .object()
  .shape<Partial<Record<keyof GanttColumnForm, yup.AnySchema>>>({
    code: yup.string().test('required', '', val => Boolean(val?.trim())),
    title: yup.string().test('required', '', val => Boolean(val?.trim())),
    field: yup.object().shape({
      field: requiredFieldPicker,
    }),
  })

export const resourceFormSchema = yup.object().shape({
  key: yup.object().shape({
    field: requiredFieldPicker,
  }),
  data: yup.object().shape({
    id: yup.string().test('required', '', val => Boolean(val?.trim())),
    label: yup.string().test('required', '', val => Boolean(val?.trim())),
  }),
  commands: yup.array().of(
    yup.object().shape({
      type: yup.object().shape({
        id: yup
          .string()
          .required()
          .test('required', '', val => Boolean(val?.trim())),
        label: yup
          .string()
          .required()
          .test('required', '', val => Boolean(val?.trim())),
      }),
      name: yup.object().shape({
        id: yup
          .string()
          .required()
          .test('required', '', val => Boolean(val?.trim())),
        label: yup
          .string()
          .required()
          .test('required', '', val => Boolean(val?.trim())),
      }),
    })
  ),
  columns: yup
    .array()
    .of(ganttColumnFormSchema)
    .min(1, i18next.t('error.ganttConfiguration.minColumnsError')),
})

export const sectionFormSchema = yup
  .object()
  .shape<Partial<Record<keyof SectionType, yup.AnySchema>>>({
    link: yup.object().shape({
      axisY: yup.object().shape({
        field: requiredFieldPicker,
      }),
      axisX: yup.object().shape({
        field: requiredFieldPicker,
      }),
      systemName: yup.string().test('required', '', val => Boolean(val?.trim())),
    }),
    datetimeStart: yup.object().shape({
      field: requiredFieldPicker,
    }),
    datetimeEnd: yup.object().shape({
      field: requiredFieldPicker,
    }),
    title: yup.object().shape({
      field_array: yup.array().of(notRequiredFieldPicker),
      field: notRequiredFieldPicker,
    }),
    tooltip: yup.object().shape({
      field_array: yup.array().of(notRequiredFieldPicker),
      field: notRequiredFieldPicker,
    }),
  })

export const timelineFormSchema = yup
  .object()
  .shape<Partial<Record<keyof TimelineItem, yup.AnySchema>>>({
    key: yup.object().shape({
      field: requiredFieldPicker,
    }),
    data: yup.object().shape({
      id: yup.string().test('required', '', val => Boolean(val?.trim())),
      label: yup.string().test('required', '', val => Boolean(val?.trim())),
    }),
    commands: yup.array().of(
      yup.object().shape({
        type: yup.object().shape({
          id: yup
            .string()
            .required()
            .test('required', '', val => Boolean(val?.trim())),
          label: yup
            .string()
            .required()
            .test('required', '', val => Boolean(val?.trim())),
        }),
        name: yup.object().shape({
          id: yup
            .string()
            .required()
            .test('required', '', val => Boolean(val?.trim())),
          label: yup
            .string()
            .required()
            .test('required', '', val => Boolean(val?.trim())),
        }),
      })
    ),
    title: yup.object().shape({
      field_array: yup.array().of(notRequiredFieldPicker),
      field: notRequiredFieldPicker,
    }),
    tooltip: yup.object().shape({
      field_array: yup.array().of(notRequiredFieldPicker),
      field: notRequiredFieldPicker,
    }),
    sections: yup
      .array()
      .of(sectionFormSchema)
      .min(1, i18next.t('error.ganttConfiguration.minSegmentsError')),
  })

export const ganttConfigurationSchema = yup
  .object()
  .shape<Partial<Record<keyof GanttFormValues, yup.AnySchema>>>({
    code: yup.string().test('required', '', val => Boolean(val?.trim())),
    title: yup.string().test('required', '', val => Boolean(val?.trim())),
    resource: resourceFormSchema,
    timeline: yup
      .array()
      .of(timelineFormSchema)
      .min(1, i18next.t('error.ganttConfiguration.minBarsError')),
    sort: yup.object().shape({
      code: yup.string().test('required', '', val => Boolean(val?.trim())),
      value: yup.array().of(
        yup.object().shape({
          field: yup.object().shape({
            pathStr: yup
              .string()
              .test(
                'emptyOrder',
                i18next.t('error.ganttConfiguration.defaultSortFillBothError'),
                function (val, ctx) {
                  const sortRow = ctx.from?.[1]

                  return !(sortRow?.value.order && !val)
                }
              )
              .nullable(),
            pathArray: yup.array().of(
              yup.object().shape<Partial<Record<keyof EmbeddedObject, yup.AnySchema>>>({
                field: yup.string().test('required', '', val => Boolean(val)),
              })
            ),
          }),
          order: yup
            .string()
            .when('field', ([field], schema) => {
              if (field?.pathStr) {
                return schema.test(
                  'emptyField',
                  i18next.t('error.ganttConfiguration.defaultSortFillBothError'),
                  val => Boolean(val?.trim())
                )
              }

              return schema
            })
            .nullable(),
        })
      ),
    }),
  })
