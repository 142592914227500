import { createContext, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { Box, Typography } from '@mui/material'

import { AllDisplayRules } from '@pages/DisplayRules/components/AllDisplayRules'
import { useDisplayRules } from '@pages/DisplayRules/hooks'
import { PageContextType } from '@pages/DisplayRules/types'

import { PageContentLayout } from '@layouts/PageContentLayout'
import { PageTitle } from '@components/PageTitle'

import { VISIBLE_HIDDEN } from '@constants'

export const PageContext = createContext<PageContextType>({} as PageContextType)

export const DisplayRules: FC = () => {
  const { t } = useTranslation()

  const {
    state: { methods, allDisplayRules },
    data: { isAllDisplayRules, objects, isLoadingDisplayRules },
    handlers: { handleDeleteDisplayRule, handleEditDisplayRule, handleCreateDisplayRule },
  } = useDisplayRules()

  const contextValue = {
    displayRulesTables: allDisplayRules,
    isLoadingDisplayRules,
    objects,
    onCreateDisplayRule: handleCreateDisplayRule,
    onEditDisplayRule: handleEditDisplayRule,
    onDeleteDisplayRule: handleDeleteDisplayRule,
  }

  return (
    <PageContext.Provider value={contextValue}>
      <Outlet />
      <Box
        sx={{
          ...(!isAllDisplayRules && VISIBLE_HIDDEN),
        }}
      >
        <PageTitle
          end={false}
          start={
            <>
              <Typography display={'inline'} sx={{ mr: 1, ml: 1 }} variant={'h6'}>
                {t('pageTitle.displayRules')}
              </Typography>
              <Typography display={'inline'} variant={'subtitle2'}>
                {t('pageTitle.displayRulesControl')}
              </Typography>
            </>
          }
        />

        <PageContentLayout>
          <AllDisplayRules methods={methods} />
        </PageContentLayout>
      </Box>
    </PageContext.Provider>
  )
}
