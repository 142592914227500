import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import MaintenanceNew from '@assets/images/common/maintenance-new.ico'
import { Grid, IconButton } from '@mui/material'

import { CustomSvgIcon } from '@components/CustomSvgIcon'

import { useAuth } from '@hooks'
import { ENTITY, ROUTES, USER_ROLES } from '@constants'

type JumpButtonToConfigProps = {
  entityType?: ENTITY
  code: string | null | undefined
  type: 'form' | 'entity'
}

export const JumpButtonToConfig: FC<JumpButtonToConfigProps> = ({ entityType, code, type }) => {
  const { t } = useTranslation()
  const { hasRole } = useAuth()
  const isAdmin = hasRole([USER_ROLES.ADMIN])

  const openConfigInNewTabBrowser = ({ entityType, code, type }: JumpButtonToConfigProps) => {
    if (type === 'form') {
      return window.open(`${ROUTES.CONFIG_FORMS_EDIT}/${code}`, '_blank')
    } else {
      switch (entityType) {
        case ENTITY.DROP_DOWN_ENTITY: {
          return window.open(`${ROUTES.CONFIG_DROP_DOWN_ENTITY_EDIT}/${code}`, '_blank')
        }
        case ENTITY.VIEW: {
          return window.open(`${ROUTES.CONFIG_VIEWS_EDIT}/${code}`, '_blank')
        }
        case ENTITY.LIST_CONTROL: {
          return window.open(`${ROUTES.CONFIG_LIST_CONTROLS_EDIT}/${code}`, '_blank')
        }
        default: {
          return
        }
      }
    }
  }

  return isAdmin ? (
    <Grid item marginLeft={'auto'}>
      <IconButton
        size={'small'}
        title={t('tooltip.openConfigInNewTabBrowser')}
        onClick={() => openConfigInNewTabBrowser({ entityType, code, type })}
      >
        <CustomSvgIcon src={MaintenanceNew} sx={{ width: '27px', height: '27px' }} />
      </IconButton>
    </Grid>
  ) : (
    <></>
  )
}
