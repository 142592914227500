import i18next from 'i18next'
import * as yup from 'yup'

export const ganttParametersFormSchema = yup.object().shape({
  rowHeight: yup
    .mixed()
    .test('min', i18next.t('error.ganttParametersForm.rowHeightMinMax'), value => {
      if (!value && value !== 0) {
        return true
      }

      return !(value < 20)
    }),
  backlogHeight: yup.mixed().when('hasBacklog', {
    is: true,
    then: schema => {
      return schema.test('min', i18next.t('error.ganttParametersForm.backlogHeightMin'), value => {
        if (!value && value !== 0) {
          return true
        }

        return !(value < 100)
      })
    },
  }),
  yAxisWidth: yup
    .mixed()
    .test('min', i18next.t('error.ganttParametersForm.yAxisWidthMin'), value => {
      if (!value && value !== 0) {
        return true
      }

      return !(value < 50)
    }),
  axisX: yup.object().shape({
    realtimeLineWidth: yup.mixed().when('hasRealtimeLine', {
      is: true,
      then: schema => {
        return schema.test(
          'val',
          i18next.t('error.ganttParametersForm.realtimeLineWidth'),
          value => {
            if (!value && value !== 0) {
              return true
            }

            return /^([1-4]{1}(\.5|\.0)?|5(\.0)?)$/.test(value as string)
          }
        )
      },
    }),
  }),
  rowExpansionHeight: yup.mixed().when('hasRowExpansion', {
    is: true,
    then: schema => {
      return schema.test(
        'min',
        i18next.t('error.ganttParametersForm.rowExpansionHeightMin'),
        value => {
          if (!value && value !== 0) {
            return true
          }

          return !(value < 100)
        }
      )
    },
  }),
})
