import moment from 'moment'

import {
  CellsDisplayRuleValueProps,
  CellsDisplayRuleValueType,
} from '@pages/ConfiguredEntity/types'

import { ViewRow } from '@types'

export const getClassNameCell = (cellsDisplayRuleValueProps: CellsDisplayRuleValueProps) => {
  const {
    entityStylesNames,
    columnStylesNames,
    globalStylesNames,
    pinnedColumnCellClassName,
    displayRulesObject,
    params,
    columnsObject,
  } = cellsDisplayRuleValueProps
  const column = columnsObject[params?.field as keyof ViewRow] as ViewRow
  const cellDisplayRules = displayRulesObject[
    params?.field as keyof CellsDisplayRuleValueType
  ] as CellsDisplayRuleValueType[]

  let className = ''
  let codeStyles = ''

  const getStyleJSDisplayRule = (srcObj: any, script: string) => {
    const getStyleCode = new Function('srcObj', 'moment', script)
    try {
      return getStyleCode(srcObj, moment)
    } catch {
      return undefined
    }
  }

  if (column && cellDisplayRules) {
    const displayRule = column?.displayRule
    const jsSetting = displayRule?.jsSetting

    cellDisplayRules.forEach(rule => {
      if (!displayRule?.displayRuleValues) {
        const script = jsSetting?.styleSettingScript ?? ''
        const row = params?.row

        codeStyles = getStyleJSDisplayRule(row, script)

        const isJsCell =
          rule?.columnCode?.toString() === params?.field?.toString() &&
          column.value === params?.colDef?.columnValue &&
          rule?.jsCode === codeStyles
        if (isJsCell) {
          className = rule?.className ?? ''
        }
      } else {
        const isValueCell =
          column.useDisplayRule &&
          displayRule?.displayRuleValues &&
          column.code === params?.field &&
          rule.displayRuleValue === params?.value?.toString()

        if (isValueCell) {
          className = rule?.className ?? ''
        }
      }
    })
  }

  return `${globalStylesNames.text} ${entityStylesNames.text} ${pinnedColumnCellClassName} ${columnStylesNames.text} ${className}`
}
