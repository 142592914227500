import { DateRangeValue } from '@microservices/wiskey-react-components'

import { RelativeType } from '@pages/ConfiguredEntity/components/SearchAssistant/components/RelativeDatePicker'

import i18next from '../i18n'
import { AutocompleteOption, GETCriteriaValueType, POSTStoredValueType } from '../types'

import { FIELD_VALUE_TYPE } from './forms'
import { NAMES_FIELDS } from './names'
import { OBJECT_FIELD_TYPE } from './object'

export const COUNT_FORM_FIELD = 5

export enum ASSISTANT_TYPE {
  CREATE = 'CREATE',
  SAVED = 'SAVED',
  HIDE = 'HIDE',
}

export type ValueAddType = {
  valueId?: number
  codeCriteria: string
  searchRule: FILTERS_SA_TYPE
  value: ValueType
}

export type ValueType =
  | string
  | [DateRangeValue, DateRangeValue]
  | null
  | number
  | AutocompleteOption[]
  | AutocompleteOption
  | RelativeType

export type ValueTypeForRequest =
  | (string | number)[]
  | string
  | string[]
  | [DateRangeValue, DateRangeValue]
  | number[]
  | RelativeType[]
  | null[]
  | [DateRangeValue, string, string]

export type StoredValueType = {
  type: FIELD_VALUE_TYPE
  name: string
} & POSTStoredValueType

export type CriteriaType = {
  id: number
  title: string
  codeCriteria: string
  order: number | null
  storedValues: CriteriaStoredValue[]
  isPinning: boolean
}

export type CriteriaStoredValue = GETCriteriaValueType & { searchRule: FILTERS_SA_TYPE }

export const enum FILTERS_SA_TYPE {
  CONTAIN = 'CONTAIN',
  NOT_CONTAIN = 'NOT_CONTAIN',
  EQUAL = 'EQUAL',
  NOT_EQUAL = 'NOT_EQUAL',
  STARTS_WITH = 'STARTS_WITH',
  ENDS_WITH = 'ENDS_WITH',
  EMPTY = 'EMPTY',
  NOT_EMPTY = 'NOT_EMPTY',
  MORE = 'MORE',
  LESS = 'LESS',
  MORE_OR_EQUAL = 'MORE_OR_EQUAL',
  LESS_OR_EQUAL = 'LESS_OR_EQUAL',
}

type FilterSAType = Record<
  FILTERS_SA_TYPE,
  {
    value: { id: FILTERS_SA_TYPE; label: string }
    types: (OBJECT_FIELD_TYPE | FIELD_VALUE_TYPE)[]
    excludeNames: string[]
    includeInName: string[]
  }
>

export const FILTERS_SA: FilterSAType = {
  CONTAIN: {
    value: {
      id: FILTERS_SA_TYPE.CONTAIN,
      label: i18next.t('filter.rules.contain'),
    },
    types: [
      OBJECT_FIELD_TYPE.SINGLE,
      OBJECT_FIELD_TYPE.OBJECT_LINK,
      FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
      FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
    includeInName: [NAMES_FIELDS.REF],
  },
  NOT_CONTAIN: {
    value: {
      id: FILTERS_SA_TYPE.NOT_CONTAIN,
      label: i18next.t('filter.rules.notContain'),
    },
    types: [
      OBJECT_FIELD_TYPE.SINGLE,
      OBJECT_FIELD_TYPE.OBJECT_LINK,
      FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
      FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
    includeInName: [NAMES_FIELDS.REF],
  },
  EQUAL: {
    value: {
      id: FILTERS_SA_TYPE.EQUAL,
      label: i18next.t('filter.rules.equal'),
    },
    types: [
      OBJECT_FIELD_TYPE.SINGLE,
      OBJECT_FIELD_TYPE.OBJECT_LINK,
      FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      OBJECT_FIELD_TYPE.ENUM,
      FIELD_VALUE_TYPE.INTEGER,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
      FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK,
    ],
    excludeNames: [],
    includeInName: [],
  },
  NOT_EQUAL: {
    value: {
      id: FILTERS_SA_TYPE.NOT_EQUAL,
      label: i18next.t('filter.rules.notEqual'),
    },
    types: [
      OBJECT_FIELD_TYPE.SINGLE,
      OBJECT_FIELD_TYPE.OBJECT_LINK,
      FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      OBJECT_FIELD_TYPE.ENUM,
      FIELD_VALUE_TYPE.INTEGER,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
      FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK,
    ],
    excludeNames: [],
    includeInName: [],
  },
  STARTS_WITH: {
    value: {
      id: FILTERS_SA_TYPE.STARTS_WITH,
      label: i18next.t('filter.rules.startsWith'),
    },
    types: [
      OBJECT_FIELD_TYPE.SINGLE,
      OBJECT_FIELD_TYPE.OBJECT_LINK,
      FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
      FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
    includeInName: [NAMES_FIELDS.REF],
  },
  ENDS_WITH: {
    value: {
      id: FILTERS_SA_TYPE.ENDS_WITH,
      label: i18next.t('filter.rules.endsWith'),
    },
    types: [
      OBJECT_FIELD_TYPE.SINGLE,
      OBJECT_FIELD_TYPE.OBJECT_LINK,
      FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
      FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
    includeInName: [NAMES_FIELDS.REF],
  },
  EMPTY: {
    value: {
      id: FILTERS_SA_TYPE.EMPTY,
      label: i18next.t('filter.rules.empty'),
    },
    types: [
      OBJECT_FIELD_TYPE.SINGLE,
      OBJECT_FIELD_TYPE.OBJECT_LINK,
      FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      OBJECT_FIELD_TYPE.ENUM,
      FIELD_VALUE_TYPE.INTEGER,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
      FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK,
    ],
    excludeNames: [],
    includeInName: [],
  },
  NOT_EMPTY: {
    value: {
      id: FILTERS_SA_TYPE.NOT_EMPTY,
      label: i18next.t('filter.rules.notEmpty'),
    },
    types: [
      OBJECT_FIELD_TYPE.SINGLE,
      OBJECT_FIELD_TYPE.OBJECT_LINK,
      FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      OBJECT_FIELD_TYPE.ENUM,
      FIELD_VALUE_TYPE.INTEGER,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
      FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK,
    ],
    excludeNames: [],
    includeInName: [],
  },
  MORE: {
    value: {
      id: FILTERS_SA_TYPE.MORE,
      label: i18next.t('filter.rules.more'),
    },
    types: [
      OBJECT_FIELD_TYPE.SINGLE,
      OBJECT_FIELD_TYPE.OBJECT_LINK,
      FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.INTEGER,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
      FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
    includeInName: [NAMES_FIELDS.REF],
  },
  LESS: {
    value: {
      id: FILTERS_SA_TYPE.LESS,
      label: i18next.t('filter.rules.less'),
    },
    types: [
      OBJECT_FIELD_TYPE.SINGLE,
      OBJECT_FIELD_TYPE.OBJECT_LINK,
      FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.INTEGER,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
      FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
    includeInName: [NAMES_FIELDS.REF],
  },
  MORE_OR_EQUAL: {
    value: {
      id: FILTERS_SA_TYPE.MORE_OR_EQUAL,
      label: i18next.t('filter.rules.moreOrEqual'),
    },
    types: [
      OBJECT_FIELD_TYPE.SINGLE,
      OBJECT_FIELD_TYPE.OBJECT_LINK,
      FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.INTEGER,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
      FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
    includeInName: [NAMES_FIELDS.REF],
  },
  LESS_OR_EQUAL: {
    value: {
      id: FILTERS_SA_TYPE.LESS_OR_EQUAL,
      label: i18next.t('filter.rules.lessOrEqual'),
    },
    types: [
      OBJECT_FIELD_TYPE.SINGLE,
      OBJECT_FIELD_TYPE.OBJECT_LINK,
      FIELD_VALUE_TYPE.BOOLEAN,
      FIELD_VALUE_TYPE.STRING,
      FIELD_VALUE_TYPE.INTEGER,
      FIELD_VALUE_TYPE.OBJ_PK_LINK,
      FIELD_VALUE_TYPE.OBJ_INTERNAL_ID_LINK,
    ],
    excludeNames: [NAMES_FIELDS._ID],
    includeInName: [NAMES_FIELDS.REF],
  },
}

export const FILTERS_DIRTY_TYPES = [
  FILTERS_SA_TYPE.CONTAIN,
  FILTERS_SA_TYPE.NOT_CONTAIN,
  FILTERS_SA_TYPE.EQUAL,
  FILTERS_SA_TYPE.NOT_EQUAL,
  FILTERS_SA_TYPE.ENDS_WITH,
  FILTERS_SA_TYPE.STARTS_WITH,
  FILTERS_SA_TYPE.MORE,
  FILTERS_SA_TYPE.LESS,
  FILTERS_SA_TYPE.MORE_OR_EQUAL,
  FILTERS_SA_TYPE.LESS_OR_EQUAL,
]
