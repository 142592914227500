import { FC, useEffect, useState } from 'react'
import { Box, SxProps, Theme } from '@mui/material'

import { AppLoader } from '@components/AppLoader'

const sxNoRowsOverlay: SxProps<Theme> = {
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 4,
}

export const CustomNoRowsOverlay: FC = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), 300)

    return () => clearTimeout(timeout)
  }, [])

  if (!show) return <AppLoader />

  return <Box sx={sxNoRowsOverlay}>No rows</Box>
}
