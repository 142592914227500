import { useNavigate } from 'react-router-dom'

import { useDeleteDisplayRuleMutation } from '@redux/api'

import { MODAL_TYPE, ROUTES } from '@constants'

export const useHandlers = () => {
  const navigate = useNavigate()

  const [deleteDisplayRule] = useDeleteDisplayRuleMutation()

  const handleCreateDisplayRule = () => navigate(MODAL_TYPE.CREATE)

  const handleEditDisplayRule = (id: number | string) =>
    navigate(`${ROUTES.CONFIG_DISPLAY_RULES_EDIT}/${id}`)

  const handleDeleteDisplayRule = (id: number) => deleteDisplayRule(id)

  return {
    state: {},
    handlers: {
      handleEditDisplayRule,
      handleCreateDisplayRule,
      handleDeleteDisplayRule,
    },
  }
}
